// gatsby-shared.js
import React from 'react';
import useIP from './src/hooks/useIP';
import UserContextProvider from './src/context/UserContextProvider';

const App = ({ children }) => {
  useIP();

  return <>{children}</>;
};

export const wrapRootElement = ({ element }) => {
  return (
    <UserContextProvider>
      <App>{element}</App>
    </UserContextProvider>
  );
};
