// src/context/UserContextProvider.js
import React, { useState, createContext } from 'react';
import useHasScrolled from '../hooks/useHasScrolled';
import '../styles/main.scss';

export const UserContext = createContext();

const UserContextProvider = ({ children }) => {
  const currencyTypeOptions = [
    { value: 'AED', label: 'AED', symbol: 'د.إ' },
    { value: 'EUR', label: 'EUR', symbol: '€' },
    { value: 'GBP', label: 'GBP', symbol: '£' },
    { value: 'USD', label: 'USD', symbol: '$' },
  ];

  const [currencyType, setCurrencyType] = useState(
    (typeof window !== 'undefined' &&
      localStorage.getItem('currency') &&
      JSON.parse(localStorage.getItem('currency'))) || 
    { value: 'AED', label: 'AED', symbol: 'د.إ' }
  );

  const scrolled = useHasScrolled();
  const [searchhookareaVal, setHookAreaVal] = useState('');

  return (
    <UserContext.Provider
      value={{ searchhookareaVal, setHookAreaVal, currencyType, setCurrencyType, currencyTypeOptions }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
